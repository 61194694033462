import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { createMetaManager } from 'vue-meta'
import api from '~api'
import moment from 'moment'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import './assets/scss/style.scss'

const app = createApp(App)
  .use(store)
  .use(router)
  .use(createMetaManager())
store.$api = store.state.$api = api
app.provide('moment', moment)
app.provide('g_companyNameL', '深圳市尚行认证咨询有限公司')
app.config.globalProperties.companyNameS = '尚行'
app.config.globalProperties.companyNameM = '深圳尚行'
app.config.globalProperties.companyNameL = '深圳市尚行认证咨询有限公司'
app.config.globalProperties.companyICP = '粤ICP备2023075856号-1'
app.config.globalProperties.companyBmapLL = { long: 114.07558491468797, lat: 22.539328619643634 }
app.mount('#app')
