import api from '@/js/http-client'
import debounce from '@/js/debounce'

function parseInitState(key) {
  const itemStr = localStorage.getItem(key) != null ? localStorage.getItem(key) : null
  let obj
  try {
    obj = JSON.parse(itemStr)
    // //console.log('### parse localStorage => ', obj)
    return obj
  } catch (error) {
    // //console.log(error)
  }
}

const fetchSharedInfo = debounce(async (store) => {
  // //console.log('### fetchSharedInfo')
  await api.postJson('commonInfomation', { companyType: '2' }).then(
    (dat) => {
      // //console.log(dat)
      store(dat)
    }).catch(() => { })
}, 3000)

function formatTel(tel = '') {
  return [tel.substring(0, 3), tel.substring(3, 7), tel.substring(7,)].join('-')
}

export default {
  namespace: 'true',
  state() {
    return {
      companyInfo: parseInitState('YH_companyInfo') ??
      {
        address: undefined,
        tel: undefined,
        formattedTel: undefined,
        site: undefined,
        mail: undefined,
        wxUrl: undefined,
      },
      projectMenuInfo: parseInitState('YH_projectMenuInfo') ?? [],
      footerProjectList: parseInitState('YH_footerProjectList') ?? [],
    }
  },
  mutations: {
    saveCompanyInfo(state, companyInfo) {
      // //console.log('mutation.saveCompanyInfo => ', companyInfo)
      if (!companyInfo) return
      companyInfo.formattedTel = formatTel(companyInfo.tel)
      state.companyInfo = companyInfo
      localStorage.setItem('YH_companyInfo', JSON.stringify(companyInfo ?? {})) // 同步保存 localStorage
    },
    saveProjectMenuInfo(state, projectMenuInfo) {
      // //console.log('mutation.saveProjectMenuInfo => ', projectMenuInfo)
      if (!projectMenuInfo) return
      state.projectMenuInfo = projectMenuInfo
      localStorage.setItem('YH_projectMenuInfo', JSON.stringify(projectMenuInfo ?? []))
    },
    saveFooterProjectList(state, footerProjectList) {
      // //console.log('mutation.saveProjectMenuInfo => ', footerProjectList)
      if (!footerProjectList) return
      state.footerProjectList = footerProjectList
      localStorage.setItem('YH_footerProjectList', JSON.stringify(footerProjectList ?? []))
    },
    clearCompanyInfo(state) {
      state.companyInfo = {}
      localStorage.removeItem('YH_companyInfo') // 同步清空 localStorage
    },
    clearProjectMenuInfo(state) {
      state.projectMenuInfo = []
      localStorage.removeItem('YH_projectMenuInfo')
    },
    clearFooterProjectList(state) {
      state.footerProjectList = []
      localStorage.removeItem('YH_footerProjectList')
    }
  },
  actions: {
    updateCommonInfo(context) {
      fetchSharedInfo((commonInfo) => {
        context.commit('saveCompanyInfo', commonInfo.companyInformation)
        context.commit('saveProjectMenuInfo', commonInfo.simpleProjectList)
        context.commit('saveFooterProjectList', commonInfo.bottomProjectList)
      })
    },
    clearCommonInfo(context) {
      context.commit('clearCompanyInfo')
      context.commit('clearProjectMenuInfo')
      context.commit('clearFooterProjectList')
    }
  },
  getters: {
    companyInfo(state) {
      return state.companyInfo
    },
    projectMenuInfo(state) {
      return state.projectMenuInfo
    },
    footerProjectList(state) {
      return state.footerProjectList
    },
  }
}
